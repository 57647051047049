.cmd-btn {
    width: 100%; 
    height: 7em;
}

.small-btn-font {
    font-size: 1rem;
}

.double-underline { border-bottom: 2px double; }

.badge, .badge-width {
    width: 95px;
}

.delay-2 {
    animation-delay: 2s;
}

.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

.col-last {
  flex: 0 0 auto !important;
    width: 70% !important;
}
